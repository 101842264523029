// ===============================================================================================
//  File Name: error.scss
//  Description: Page content different user error page layouts SCSS.
//  ----------------------------------------------------------------------------------------------
//  Item name: Vuexy  - Vuejs, HTML & Laravel Admin Dashboard Template
//  Author: PIXINVENT
//  Author URL: http://www.themeforest.net/user/pixinvent
// ================================================================================================

.error-code{
    font-size:10rem;
}